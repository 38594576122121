<template>
  <section id="entity-users-list" 
  v-loading.fullscreen.lock="downloadLoading"
  :element-loading-text="loadingText">
    <div class="inner-navbar">
      <!-- <el-row>
        <el-col :lg="16" :md="14" :sm="12" :xs="24">
          <div class="icon-block d-flex align-center">
            <div class="icon-text pl-1">
              <h3 class="table-head-text">
                {{ relationshipDetails.relationship_title }}
              </h3>
            </div>
          </div>
        </el-col>

        <el-col :lg="8" :md="10" :sm="12" :xs="24"> </el-col>
      </el-row> -->
      <el-row v-if="nextRealationshipDetails && !loading && !hideLabels">
        <el-col :lg="24" :md="24" :sm="24" :xs="24">
          <el-button
            class="right-block float-right mt-10 button-uppercase"
            style="padding: 6px 6px !important"
            size="mini"
            @click="gotoNextStep"
          >
            Next
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div class="inner-navbar-content top">
      <div class="vue-data-table-default">
        <data-tables-server
          :data="data"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          v-loading="loading"
        >
          <el-table-column
            min-width="170"
            v-for="(step, index) of getFilterColumList"
            :label="step.label"
            :key="step.template_id + '-' + step.id + '-' + step.label +'-'+index"
          >
            <template slot-scope="scope">
              <div
                class="pl-15"
                v-if="
                  scope.row.entityData &&
                  scope.row.entityData[step.template_id] &&
                  scope.row.entityData[step.template_id][`${step.id}_code`] &&
                  scope.row.entityData[step.template_id][`${step.id}_code`]
                    .formatInternational
                "
              >
                {{
                  scope.row.entityData[step.template_id][`${step.id}_code`]
                    .formatInternational
                }}
              </div>

              <div
                class="pl-15"
                v-else-if="
                  scope.row.entityData &&
                  scope.row.entityData[step.template_id] &&
                  scope.row.entityData[step.template_id][
                    `${step.id}_currency`
                  ] &&
                  scope.row.entityData[step.template_id][`${step.id}_currency`]
                "
              >
                <span
                  v-if="
                    scope.row.entityData[step.template_id][
                      `${step.id}_currency`
                    ] === 'USD'
                  "
                >
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(scope.row.entityData[step.template_id][step.id])
                  }}</span
                >
                <span
                  v-else-if="
                    scope.row.entityData[step.template_id][
                      `${step.id}_currency`
                    ] === 'INR'
                  "
                >
                  {{
                    Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(scope.row.entityData[step.template_id][step.id])
                  }}</span
                >
                <span
                  v-else-if="
                    scope.row.entityData[step.template_id][
                      `${step.id}_currency`
                    ] === 'EUR'
                  "
                >
                  {{
                    Intl.NumberFormat("en-IE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(parseFloat(num).toFixed(2))
                  }}</span
                >
                <span
                  v-else-if="
                    scope.row.entityData[step.template_id][
                      `${step.id}_currency`
                    ] === 'CAD'
                  "
                >
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "CAD",
                    }).format(scope.row.entityData[step.template_id][step.id])
                  }}</span
                >
                <span
                  v-else-if="
                    scope.row.entityData[step.template_id][
                      `${step.id}_currency`
                    ] === 'AUD'
                  "
                >
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "AUD",
                    }).format(scope.row.entityData[step.template_id][step.id])
                  }}</span
                >
              </div>
              <div style="font-size: 13px" v-else-if="step && step.type == 'MULTI_LINE_TEXT'">
                <span v-if="step && step.type == 'MULTI_LINE_TEXT' && step.currency_code.data_type">
                <span v-if="getFieldValue(scope.row, step)">
                <span v-if="getFieldValue(scope.row, step).length > 100">
                                {{ truncateText(getPlainText(getFieldValue(scope.row, step))) }}
                <a href="#" @click.prevent="openMultiLineData(getFieldValue(scope.row, step), step.label)" style="color: #409eff">Read more</a>
                </span>
                <span v-else>
                                {{ getPlainText(getFieldValue(scope.row, step)) }}
                              </span>
                            </span>
                          </span>
                  </div>
              <div v-else-if="step && step.type == 'DATE'">
                {{ getFieldValue(scope.row, step) | globalDateFormat }}
              </div>
              <div v-else-if="step && step.type == 'FILE'">
                  <div
                    v-if="
                      getFieldValue(scope.row, step) &&
                      getFieldValue(scope.row, step).length &&
                      getFieldValue(scope.row, step).length <= 1
                    "
                  >
                    <el-button
                      type="text"
                      style="margin-top: -14px"
                      @click="onDownloadFile(getFieldValue(scope.row, step)[0])"
                      class="mb-1"
                    >
                      <p style="font-size: 13px">
                        Download <i class="el-icon-download"></i>
                      </p>
                    </el-button>
                  </div>
                  <div
                  class="d-flex"
                    v-else-if="
                      getFieldValue(scope.row, step) &&
                      getFieldValue(scope.row, step).length &&
                      getFieldValue(scope.row, step).length >= 1
                    "
                  >
                        <p
                          v-for="(file, index) of getFieldValue(
                            scope.row,
                            step
                          )"
                          :key="index"
                        >
                         <el-button
                            type="text"
                            style="margin-top: -10px"
                            v-if="file && file.name && index < limit"
                            @click="
                              onDownloadFile(getFieldValue(scope.row, step)[0])
                            "
                          >
                            {{ file.name | truncate(10, "...") }}
                            <i class="el-icon-download"></i>
                          </el-button>
                        
                        </p>
                        <el-tag
                          style="cursor: pointer; width: 40px"
                          class="ml-1"
                          :value="getFieldValue(scope.row, step).length"
                          v-if="getFieldValue(scope.row, step).length > 1"
                          @click="downloadDocumentsPopup(scope.row, step)"
                        >
                          +{{ getFieldValue(scope.row, step).length - 1 }}
                        </el-tag>
                  </div>
                  <!-- </p> -->
                </div>   
              <div class="pl-15" v-else>
                {{ getFieldValue(scope.row, step) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-button
              v-if="checkUrlType() != 'public'"
                icon="el-icon-top-right"
                circle
                @click="goToEntityData(scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </data-tables-server>
      </div>
    </div>
    <div>
      <dialog-component
      :visible="downloadDocuments"
      :containerWidth="'50%'"
      :containerHeight="'75%'"
      :title="'Download Documents'"
      @before-close="closeDownloadDocuments"
    >
      <el-row>
        <el-input
          size="small"
          placeholder="Search"
          v-model="searchDocument"
          clearable
          @input="searchDocumentName"
          prefix-icon="el-icon-search"
          style="float : right; width: 35%; margin: 7px 0px;"
        ></el-input>
      </el-row>
      <div class="sidemenu">
        <el-table
          border
          :data="tableDocumentsData"
          :header-row-class-name="'my-header-row'"
          class="tableSty"
          :show-header="true"
        >
          <el-table-column class="labelSty" label="Document Name">
            <template slot-scope="scope">
              <p style="font-size: 14px" slot="reference">
                {{ scope.row.name }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Action" width="150">
            <template slot-scope="scope">
              <el-button
                type="primary"
                plain
                @click="onDownloadFile(scope.row)"
                target="_blank"
                ref="downloadLink"
                style="width: 120px; font-size: 12px"
              >
                Download
                <i class="el-icon-download"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      </dialog-component>
    </div>
    <dialog-component
    :title="multiLineTextLabel"
    :visible="openMultiLineTextDialog"
    @before-close="closeMultiLneData"
    :isShowFooter="false"
    :containerMaxWidth="'50vw'"
  >
    <div style="padding:20px" class="desription-text" v-if="isRichText">
      <!-- <ckeditor :config="editorConfig" v-model="multiLineTextData">
      </ckeditor> -->
      <div v-html="multiLineTextData">

      </div>
    </div>
    <div class="desription-text" v-else>
      {{ multiLineTextData | truncateHTML() }}
    </div>
  </dialog-component>
  </section>
</template>



<script>
import { mapGetters } from "vuex";
import config from "@/config/app";
// import Vue from "vue";
// import VueLodash from "vue-lodash";
// import lodash from "lodash";
import EntityHelper from "@/mixins/EntitiesHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import navigationHelper from "../../mixins/navigationHelper";
import moment from "moment";
// Vue.use(VueLodash, {
//   name: "custom",
//   lodash: lodash,
// });
export default {
  name: "EntityDataList",
  components: {},
  mixins: [EntityHelper, entityRelationshipMixin,navigationHelper],
  props: ["relationshipDetails", "nextRealationshipDetails"],
  data() {
    return {
      multiLineTextData: "",
      multiLineTextLabel: "",
      openMultiLineTextDialog: false,
      isRichText : false,
      filterText: "",
      loading: false,
      total: 0,
      currentPage: 1,
      entityDataId: null,
      pageSize: 0,
      data: [],
      documentsLoading: false,
      firstTemplateId: null,
      primaryField: "",
      primaryKey: "",
      dialogTableVisible: false,
      entity_id: "",
      props: {
        children: "children",
        label: "label",
        disabled: "disabled",
      },

      fieldsListData: [],
      defaultKeys: [],
      selectedTableFields: [],
      filterColumList: [],
      checkedFields: [],

      parentRelatedChildEntityData: {
        child_entity_data_ids: [],
      },
      childEntityDataById: {},
      selectedEntityFields: [],
      existedDataIds: [],
      allowedDeleteForFieldRelationship: true,
      removableFieldsKeys: [],
      unAssignedFieldDetails: null,
      documentsData : [],
      tableDocumentsData : [],
      downloadDocuments : false,
      limit : 1,
      searchDocument : '',
      loadingText : "Downloading...",
      downloadLoading : false
    };
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getChildEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      "getEntityPrimaryData",
    ]),
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
    ]),

    ...mapGetters("entityRelationships", [
      "getEntityRelationshipDataStatus",
      "getEntityRelationshipDataAssignStatus",
      "getEntityRelationshipDataDetachStatus",
      "getParentEntityNestedRelationshipData",
      "getParentEntityRelatiponshipData",
    ]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    getEntityRoute() {
      return "/entity-execute/" + this.$route.params.entity_id;
    },
    logoUploadUrl() {
      return config.S3_BUCKET_URL;
    },

    getFilterColumList() {
      return this.filterColumList;
    },
  },
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    this.entity_id = this.$route.params.entity_id;
    if(this.relationshipDetails?.relationship_id){
      await this.getNecessaryInfo();

      await this.fetchParentRelatedChildEntityData();
    }
  },

  methods: {
    closeMultiLneData() {
      this.openMultiLineTextDialog = false;
      this.isRichText = null;
    },
    getPlainText(htmlContent) {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlContent;
      return tempElement.textContent || tempElement.innerText || "";
    },
    truncateText(text) {
      const maxLength = 100;
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      } else {
    return text;
  }
},
   openMultiLineData(htmlContent, label, step) {
    this.openMultiLineTextDialog = true;
    this.multiLineTextData = htmlContent;
    this.multiLineTextLabel = label;
    if ("currency_code" in step && "data_type" in step.currency_code) {
      this.isRichText = false;
    } 
    else {
      this.isRichText = true;
    }
  },
    closeMultiLineData() {
      this.openMultiLineTextDialog = false;
      this.isRichText = null;
    },
    setAllowOne() {
      this.relationshipDetails.allowOne = null;

      if (this.relationshipDetails.representation == "TAB") {
        if (
          (this.relationshipDetails.owner_type == "CHILD" &&
            this.relationshipDetails.parent_relation_type == "ONE") ||
          (this.relationshipDetails.owner_type == "PARENT" &&
            this.relationshipDetails.child_relation_type == "ONE")
        ) {
          this.relationshipDetails.allowOne = true;
        } else {
          this.relationshipDetails.allowOne = false;
        }
      } else {
        if (this.relationshipDetails.parent_relation_type == "MANY") {
          this.relationshipDetails.allowOne = false;
        } else {
          this.relationshipDetails.allowOne = true;
        }
      }
    },
    async onDownloadFile(data) {
      this.downloadLoading = true;
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a"); // Create an anchor tag
          link.href = fileURL; // Set the URL as the href attribute
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link); // Append the anchor tag to the document body
          link.click(); // Simulate a click on the anchor tag to initiate the download
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
        // this.$refs.this.getDownloadUrl.click();
      } else {
        this.downloadLoading = false;
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError,
        });
      }
    this.downloadLoading = false;
    },
    downloadDocumentsPopup(row, step) {
      this.documentsData =
        row.entityData[step.template_id][step.id].files &&
        row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      this.tableDocumentsData = this.documentsData
      this.downloadDocuments = true;
      //this.downloadDocuments = true
    },
    closeDownloadDocuments() {
      this.downloadDocuments = false;
    },
    gotoNextStep() {
      let url = this.getStepEntityRelationshipUrl(
        this.nextRealationshipDetails
      );
      this.$router.push(url);
    },
    assignEntityData() {
      this.$router.push(`/entity-execute/${this.entity_id}`);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    getFieldValue(row, step) {
      let checkIsGlobalvariable = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.id
      );
      if (
        checkIsGlobalvariable &&
        checkIsGlobalvariable.is_global_variable &&
        checkIsGlobalvariable.global_variable_id
      ) {
        let globalVariable = (this.globalVariablesData || []).find(
          (e) => e._id == checkIsGlobalvariable.global_variable_id
        );
        if (globalVariable) {
          if (
            globalVariable.input_type == "IMAGE" &&
            globalVariable.image_url
          ) {
            return globalVariable.image_url;
          } else if (globalVariable.value) return globalVariable.value;
        }
      }
      if (step.type == "WEEKDAYS") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][step.id]
        ) {
          if (
            checkIsGlobalvariable?.allow_multiple &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            let mappedArray = row.entityData[step.template_id][step.id].map(
              (e) => this.weekDayMap[e]
            );
            return mappedArray.join(",");
          } else {
            return this.weekDayMap[row.entityData[step.template_id][step.id]];
          }
        }
        return "";
      } else if (step.type == "HTML_CONTENT") {
        let newfd = this.currentEntity.templates.find((el) => {
          if (el.template_id === step.template_id) {
            return el.templateInfo;
          }
        });
        let val = newfd.templateInfo.sections[0].fields.find((el) => {
          let keyVal = el.key.split("#")[1];
          if (keyVal === step.id && el.input_type == "HTML_CONTENT") {
            return el;
          }
        });

        return val.content;
        // return row.entityData[step.template_id][step.id]
      } else if (step.type == "IMAGE") {
        let id = this.generateKeyForField(step.label);
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][id]
        ) {
          return row.entityData[step.template_id][id];
        }
      } else if (
        step.type == "FILE" &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        this.documentsData =
          row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length
            ? row.entityData[step.template_id][step.id].files
            : row.entityData[step.template_id][step.id];
        return row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      }else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        if (
          typeof row.entityData[step.template_id][step.id] == "number" &&
          this.isFloat(row.entityData[step.template_id][step.id])
        ) {
          return parseFloat(row.entityData[step.template_id][step.id]).toFixed(
            2
          );
        }
        if (step.type == "MULTI_SELECT") {
          let value = row.entityData[step.template_id][step.id]
            ? row.entityData[step.template_id][step.id]
            : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.join(",");
            }
            return value;
          }
          return "";
        } else if (
          step.type == "SELECT" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (
            field &&
            field.inputType == "SELECT" &&
            field.is_list &&
            field.global_variable_id
          ) {
            let value = field.options.find(
              (e) => e.value == row.entityData[step.template_id][step.id]
            );
            if (value && value.name) {
              result = value.name;
            }
            result = row.entityData[step.template_id][step.id];
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "LIST" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (field?.list_data) {
            let value = field.list_data.find(
              (e) => e.value == row.entityData[step.template_id][step.id]
            );
            if (value && value.name) {
              result = value.name;
            } else {
              result = row.entityData[step.template_id][step.id];
            }
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "NUMBER" &&
          row.entityData[step.template_id][step.id]
        ) {
          return typeof row.entityData[step.template_id][step.id] == "number"
            ? row.entityData[step.template_id][step.id]
            : "";
        } else if (step.type == "CHECKBOX_GROUP") {
          if (
            row.entityData &&
            step.template_id &&
            row.entityData[step.template_id] &&
            row.entityData[step.template_id][step.id] &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id].join(",")
              : "";
          } else {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id]
              : "-";
          }
        } else if (
          step.type == "TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "HH:mm:ss"
              ) +
              " - " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "HH:mm:ss"
              )
            );
          }
        } else if (
          step.type == "DATE_TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY hh:mm:ss A"
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY hh:mm:ss A"
              )
            );
          }
        } else if (
          step.type == "DATE_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY "
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY "
              )
            );
          }
        }
        if (row.entityData[step.template_id][step.id + "/name"]) {
          if (
            typeof row.entityData[step.template_id][step.id + "/name"] ==
              "object" &&
            row.entityData[step.template_id][step.id + "/name"].length
          ) {
            return row.entityData[step.template_id][step.id + "/name"].join(
              ","
            );
          } else if (
            this.checkDate(row.entityData[step.template_id][step.id + "/name"])
          ) {
            const format = this.getDefaultDateFormat
              ? this.getDefaultDateFormat
              : "MM-DD-YYYY";
            return moment(
              row.entityData[step.template_id][step.id + "/name"]
            ).format(format);
          }
          return row.entityData[step.template_id][step.id + "/name"];
        } else {
          return row.entityData[step.template_id][step.id];
        }
      }
      return "";
    },
    checkDate(string) {
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (dateStamp.test(string)) {
        return true;
      }
      return false;
    },
    prepareParams() {
      return {
        name: this.getEntityDataById.name,
        description: this.getEntityDataById.description,
        entity_type: this.getEntityDataById.entity_type,
        templates: this.getEntityDataById.templates,
        id: this.getEntityDataById._id,
        primaryFields: this.getEntityDataById.primaryFields,
        settings: this.selectedFieldKeys,
      };
    },
    async update() {
      let checked = this.$refs.tree.getCheckedNodes();

      // let selectedEntity = await lodash.filter(checked, function (node) {
      //   return !node.children;
      // });
      let selectedEntity = checked.filter(node => !node.children);
      let fields = selectedEntity.filter((e) => !e.fields);
      this.selectedFieldKeys = fields.map((field) => field);

      let params = this.prepareParams();
      await this.$store.dispatch("entities/updateEntityById", params);

      if (this.getEntityDataUpdateStatus) {
        this.loading = false;
        this.dialogTableVisible = false;
        this.$notify({
          title: "Success",
          message: "Settings saved",
          type: "success",
        });
      } else {
        this.dialogTableVisible = true;
        this.$notify({
          title: "Error",
          message: "Failed to Save the Settings",
          type: "error",
        });
        this.loading = false;
      }
    },
    checkedNodes(event) {
      this.updateNodesData(event);
    },
    updateNodesData(event) {
      if (this.selectedTableFields.indexOf(event.id) !== -1) {
        const index = this.selectedTableFields.indexOf(event.id);
        if (index > -1) {
          this.selectedTableFields.splice(index, 1);
        }
        // this.filterColumList = this._.filter(
        //   this.filterColumList,
        //   function (o) {
        //     return o.id != event.id;
        //   }
        // );
        this.filterColumList = this.filterColumList.filter(o => o.id != event.id);
      } else {
        this.filterColumList.push(event);
        this.selectedTableFields.push(event.id);
      }
    },

    async fetchParentRelatedChildEntityData() {
      try {
        this.loading = true;

        this.setAllowOne();

        let params = {
          parent_entity_id:
            this.relationshipDetails.parent_relationship_details.owner_type ==
            "PARENT"
              ? this.relationshipDetails.parent_relationship_details
                  .parent_entity_id
              : this.relationshipDetails.parent_relationship_details
                  .child_entity_id,

          parent_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_id:
            this.relationshipDetails.parent_relationship_details.owner_type ==
            "PARENT"
              ? this.relationshipDetails.parent_relationship_details
                  .child_entity_id
              : this.relationshipDetails.parent_relationship_details
                  .parent_entity_id,

          nested_child_entity_id: this.relationshipDetails.relationship_id,
        };

        // if (this.relationshipDetails.owner_type == "CHILD") {
        //   params = {
        //     parent_entity_id: this.relationshipDetails.child_entity_id,
        //     parent_entity_data_id:
        //       this.relationshipDetails.parent_entity_data_id,
        //     child_entity_id: this.relationshipDetails.parent_entity_id,
        //   };
        // }

        await this.$store.dispatch(
          "entityRelationships/fetchParentEntityNestedRelationshipData",
          params
        );

        this.parentRelatedChildEntityData = {
          ...this.getParentEntityNestedRelationshipData,
        };

        if (
          this.parentRelatedChildEntityData &&
          this.parentRelatedChildEntityData.child_entity_data_ids &&
          this.parentRelatedChildEntityData.child_entity_data_ids.length
        ) {
          this.existedDataIds =
            this.parentRelatedChildEntityData.child_entity_data_ids;
          await this.fetchEntitiesDataForTable(
            this.parentRelatedChildEntityData.child_entity_data_ids
          );
        } else {
          this.data = [];
          this.existedDataIds = [];
        }
        this.loading = false;
      } catch (err) {
        console.log("fetchParentRelatedChildEntityData",err);
        this.existedDataIds = [];
      }
    },

    async fetchChildEntityData(id) {
      try {
        this.loading = true;
        await this.$store.dispatch("entities/fetchChildEntityById", id);
        this.childEntityDataById = { ...this.getChildEntityDataById };
        this.loading = false;
      } catch (err) {
        console.log("fetchChildEntityData",err);
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      await this.fetchChildEntityData(this.relationshipDetails.relationship_id);

      if (this.getChildEntityDataById) {
        this.fieldsListData = [];
        this.filterColumList = [];
        this.selectedTableFields = [];
        let allFields = [];

        this.getChildEntityDataById.templates.map((data) => {
          let templateFields = [];
          if (
            data.templateInfo &&
            data.templateInfo.sections &&
            data.templateInfo.sections.length
          ) {
            data.templateInfo.sections[0].fields.map((field) => {
              if (this.relationshipDetails.representation == "FIELD") {
                if (
                  field.input_type == "ENTITY" &&
                  this.relationshipDetails.child_entity_id == field.entity_id
                ) {
                  if (field.validations.required) {
                    this.allowedDeleteForFieldRelationship = false;
                  } else {
                    this.allowedDeleteForFieldRelationship = true;
                    this.removableFieldsKeys = [
                      {
                        key: field.key,
                        template_id: data.template_id,
                        value: this.relationshipDetails.parent_entity_data_id,
                      },
                      {
                        key: field.key + "/name",
                        template_id: data.template_id,
                        value: this.entityDataTitle(),
                      },
                    ];

                    this.unAssignedFieldDetails = this.removableFieldsKeys;
                  }
                }
              }
              templateFields.push({
                label: field.label,
                id: field.key,
                template_id: data.template_id,
              });
            });
          }
          allFields.push(...templateFields);

          this.fieldsListData.push({
            label: data.templateInfo.name,
            id: data.templateInfo.slug + "ABC",
            template_id: data.template_id,
            children: [...templateFields],
          });
        });
        if (
          this.getChildEntityDataById.settings &&
          this.getChildEntityDataById.settings.length
        ) {
          this.selectedTableFields = [];
          this.filterColumList = this.getChildEntityDataById.settings;
          //map with latest field type
          this.filterColumList = this.mapTableFieldsType(
            this.getChildEntityDataById,
            this.filterColumList
          );
          await this.getChildEntityDataById.settings.map(async (data) => {
            // let result = this._.filter(allFields, function (o) {
            //   return o.id === data.key;
            // });
            let result = allFields.filter(o => o.id === data.key);
            if (result && result.length) {
              await this.filterColumList.push(result[0]);

              await this.selectedTableFields.push(data.key);
            }
          });
        }

        let currentEntity = this.getChildEntityDataById;
        this.selectedEntityFields = [];
        if (currentEntity && currentEntity.templates) {
          currentEntity.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.getTemplateFields(e.templateInfo),
              ];
            }
          });
        }
      }
    },

    entityDataTitle() {
      let name = "";
      if (
        this.getEntityPrimaryData &&
        this.getEntityPrimaryData.entity_prime_data
      ) {
        let key =
          this.getEntityDataById &&
          this.getEntityDataById.primaryFields &&
          this.getEntityDataById.primaryFields[0] &&
          this.getEntityDataById.primaryFields[0].key
            ? this.getEntityDataById.primaryFields[0].key
            : Object.keys(this.getEntityPrimaryData.entity_prime_data) &&
              Object.keys(this.getEntityPrimaryData.entity_prime_data)[0]
            ? Object.keys(this.getEntityPrimaryData.entity_prime_data)[0]
            : null;
        if (key) {
          return this.getEntityPrimaryData.entity_prime_data[key];
        }
      }
      return name;
    },

    async fetchEntitiesDataForTable(dataIds = []) {
      this.loading = true;
      let params = {
        entity_id: this.getChildEntityDataById._id,
        template_fields_data: this.getChildEntityDataById.templates.map(
          (template) => {
            return {
              template_id: template.template_id,
            };
          }
        ),
        filters: [],
        limit: this.pageSize,
        page: this.currentPage,
      };

      if (dataIds.length) {
        params.entity_data_ids = dataIds;
      }

      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      this.data = [];
      if (this.getEntityRecordsForTable) {
        this.data = this.getEntityRecordsForTable.data;
        this.total = this.getEntityRecordsForTable.total
          ? this.getEntityRecordsForTable.total
          : 0;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },

    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key = data._id + "#" + e.key;
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    async onDetachEntityData(row) {
      this.$confirm("Are you sure to remove the data?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.detachEntityData(row);
      });
    },
    async fetchAllEmployee() {
      this.loading = true;
      this.data = [];

      let params = {
        limit: this.pageSize,
        page: this.currentPage,
        entity_data_ids:
          this.parentRelatedChildEntityData.child_entity_data_ids,
      };

      await this.$store.dispatch("entities/fetchEntityDataById", {
        id: this.relationshipDetails.relationship_id,
        params,
      });
      this.loading = false;
      if (this.getEntityDataByEntityId) {
        this.currentPage = parseInt(this.getEntityDataByEntityId.page) || 1;
        this.total = parseInt(this.getEntityDataByEntityId.total) || 0;
        this.getEntityDataByEntityId.data &&
        this.getEntityDataByEntityId.data.length
          ? (this.data = this.getEntityDataByEntityId.data)
          : (this.data = []);
      }
    },

    backToEntities() {
      this.$router.push({ path: "/entity" });
      // this.$router.go(-1);
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.currentPage = data.page;
        await this.fetchAllEmployee();
      }
    },
    searchDocumentName(){
      this.tableDocumentsData = this.documentsData.filter(doc => doc.name.includes(this.searchDocument))
    },
    async viewWorkUserData(row) {
      if (row._id) {
        this.entityDataId = row._id;
        this.$router.push(
          `/entity_data_view/${this.entity_id}?dataId=${row._id}`
        );
      }
    },
    async editWorkUserData(row) {
      if (row._id) {
        let viewType = "STANDARD";
        if (this.currentEntity?.entity_type == "INDIVIDUAL") {
          viewType = "PROFILE";
        }
        let path = `/entityData-Edit/${this.entity_id}?dataId=${row._id}&layout=${viewType},`;
        if (this.$route.path !== path) this.$router.push(path);
        // this.$router.push(

        // );
      }
    },
    getEntityName() {
      return this.getEntityDataById?.name;
    },

    async detachEntityData(entityData) {
      let params;

      if (this.relationshipDetails.representation == "TAB") {
        if (this.relationshipDetails.owner_type == "CHILD") {
          params = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id: entityData._id,
            child_entity_data_ids: [
              this.relationshipDetails.parent_entity_data_id,
            ],
          };
        } else {
          params = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_data_ids: [entityData._id],
          };
        }
      } else {
        params = {
          parent_entity_id: this.relationshipDetails.parent_entity_id,
          child_entity_id: this.relationshipDetails.child_entity_id,
          parent_entity_data_id: entityData._id,
          child_entity_data_ids: [
            this.relationshipDetails.parent_entity_data_id,
          ],
        };
      }

      if (
        this.relationshipDetails.representation == "FIELD" &&
        this.allowedDeleteForFieldRelationship
      ) {
        params.delete_field_data = this.removableFieldsKeys;
      }

      await this.$store.dispatch(
        "entityRelationships/detachEntityRelationshipData",
        params
      );
      if (this.getEntityRelationshipDataDetachStatus) {
        this.$notify.success({
          title: "Success",
          message: "Entity Data detached successfully",
        });
        await this.fetchParentRelatedChildEntityData();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getError || "Error on deleting Entity data",
        });
      }
    },
    async settingsPopUp() {
      this.dialogTableVisible = true;
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: "region" }]);
      }
      if (node.level > 1) return resolve([]);
    },

    goToEntityData(row) {
      if (this.isApplicationUserSide) {
        let routerData = this.$router.resolve(
          `/ap/entity-execute/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}`
        );
        window.open(routerData.href, "_blank");
      } else {
        if (this.getIframeUrlInfo != null) {
          let routerData = this.$router.resolve(
          `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}`
        );
        window.open(routerData.href, "_blank");
        }
        else{
        let routerData = this.$router.resolve(
          `/entity/edit/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}`
        );
        window.open(routerData.href, "_blank");
        }
      }
      // let routerData = this.$router.resolve(
      //   `/entity_data_view/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}`
      // );
      // window.open(routerData.href, "_blank");
    },
  },
  beforeDestroy() {
    // this.$store.commit("entities/setEntityDataById", null, { root: true });
    // this.$store.commit("entities/setEntityDataByEntityId", null, {
    //   root: true,
    // });
    // this.$store.commit("entities/setEntityDataUpdateStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("templatesData/setTemplateDeleteStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("templatesData/setTemplateDeleteError", null, {
    //   root: true,
    // });
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-buttons {
  border: 1.5px solid #285ed3;
  box-sizing: border-box;
  border-radius: 2px;
  // padding: 0.45em 0.65em;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 35px;
  color: #285ed3;
  &:hover {
    background-color: #285ed3;
    color: #ffffff;
    border-color: #285ed3;
  }
}
#entity-users-list {
  // width: 90%;
  // display: table;
  margin: 10px 10px;
  .el-dialog__body {
    padding: 20px 20px;
    .el-card {
      .el-card__body {
        padding: 10px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .inner-navbar {
    margin-top: 10px;
  }
}
.doc {
  min-width: 30px;
  &.selected {
    background: green;
  }
}

.green {
  color: green;
}

.font-17 {
  font-size: 17px;
  cursor: pointer;
}

.documents {
  min-height: 200px;
  max-height: 400px;
  overflow: scroll;
}
</style>
<style lang="scss">
.pagination-bar {
  .el-pagination {
    .el-pagination__jump {
      @media (max-width: 450px) {
        display: none;
      }
    }
  }
}
</style>
